export const EU_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  // 'LV', // Latvia
  // 'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
]

export const BALTIC_COUNTRIES = ['LT', 'LV']

export const GB_COUNTRY = 'GB' // United Kingdom

export const CYPRUS_LEGAL_COUNTRIES: string[] = [
  'UA', // Ukraine
  'SK', // Slovakia
  'LV', // Latvia
  'LT', // Lithuania
]

export const UAE_LEGAL_COUNTRIES: string[] = ['AE']

export const US_LEGAL_COUNTRIES: string[] = ['US']

export const LEGAL_ENTRY_REGION: string[] = [
  // 'New Jersey',
  // 'New York',
  // 'Illinois',
  // 'Rhode Island',
]

export const COUNTRIES_WITHOUT_LEGAL_ADDRESS: string[] = []

export const TEMPORARY_UAE_LEGAL_COUNTRIES = [
  ...EU_COUNTRIES,
  ...BALTIC_COUNTRIES,
  GB_COUNTRY,
  'MU',
]
