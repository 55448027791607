import amplitude from 'amplitude-js'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import sourcebuster from 'sourcebuster'

import { saveUserUtm } from '@api/user'

import { useAppSelector } from '@reduxStore/hooks'

import { AUTOLOGIN_ROUTE } from '@constants'
import { UTMS_FROM_SOURCEBUSTER, observeUtms } from '@constants_folder/common'
import {
  SESSION_CHECKOUT_UTMS_SEND,
  SESSION_REFERRER_SEND,
  SESSION_UTMS_SEND,
} from '@constants_folder/storageKeys'

import { getUtmsFromSessionStorage } from '@hooks/saveEntryPoint'
import useWebViewMode from '@hooks/useWebViewMode'

import { useAuthContext } from '@modules/Auth/AuthProvider'

const useSaveUTMsToAmplitude = () => {
  const { query, isReady, pathname } = useRouter()
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )

  // utms saving
  useEffect(() => {
    const alreadySend = sessionStorage.getItem(SESSION_UTMS_SEND) === 'true'
    if (
      !isReady ||
      !amplitudeInited ||
      alreadySend ||
      pathname === AUTOLOGIN_ROUTE
    )
      return

    const utmParams: Record<string, string> = {}
    observeUtms
      .filter((utm: string) => !!query[utm]) // get utms from url
      .forEach((utm: string) => {
        utmParams[utm] = query[utm] as string
      })

    const identifyEvent = new amplitude.Identify()

    const initialUtmParams: Record<string, string> = {}

    // in all cases set initial utms from sourcebuster
    Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
      if (sourcebuster.get.first[key]) {
        identifyEvent.setOnce(`initial_${value}`, sourcebuster.get.first[key])
        initialUtmParams[`initial_${value}`] = sourcebuster.get.first[key]
      }
    })

    if (!utmParams.utm_source) {
      const currentUtmParams: Record<string, string> = {}

      observeUtms.forEach((utm: string) => {
        if (localStorage.getItem(`initial_${utm}`) && !initialUtmParams[utm]) {
          identifyEvent.setOnce(
            `initial_${utm}`,
            localStorage.getItem(`initial_${utm}`) || ''
          )
        }

        if (sessionStorage.getItem(utm)) {
          currentUtmParams[utm] = sessionStorage.getItem(utm) || ''
        }
      })

      Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
        if (sourcebuster.get.first[key]) {
          currentUtmParams[`${value}`] = sourcebuster.get.current[key]
        }
      })

      // send user params by one request
      amplitude.getInstance().setUserProperties(currentUtmParams)
    } else if (Object.values(utmParams).length) {
      // set initial utms
      Object.entries(utmParams).forEach(([key, value]) => {
        identifyEvent.setOnce(`initial_${key}`, value)

        // save utms to sessionStorage
        sessionStorage.setItem(key, value)

        // if the user came with english-improve.com add User_Source
        if (value.indexOf('english-improve.com')) {
          utmParams.User_Source = 'Funnel'
        }

        if (!localStorage.getItem(`initial_${key}`)) {
          // save initial utms to localStorage
          localStorage.setItem(`initial_${key}`, value)
        }
      })

      // send user params by one request

      amplitude.getInstance().setUserProperties(utmParams)
    }

    amplitude.getInstance().identify(identifyEvent) // send initial utms
    sessionStorage.setItem(SESSION_UTMS_SEND, 'true')
  }, [query, isReady, amplitudeInited])

  // referrer saving
  useEffect(() => {
    const alreadySend = sessionStorage.getItem(SESSION_REFERRER_SEND) === 'true'
    if (!amplitudeInited || alreadySend) return

    const identifyEvent2 = new amplitude.Identify()
    const referrerParams: Record<string, string> = {}

    referrerParams.referring_domain = document.referrer.split('/')[2] || ''
    referrerParams.referrer = document.referrer || ''
    referrerParams.landing_page =
      window.location.origin + window.location.pathname || ''
    identifyEvent2.setOnce('initial_referrer', referrerParams.referrer)
    identifyEvent2.setOnce(
      'initial_referring_domain',
      referrerParams.referring_domain
    )
    identifyEvent2.setOnce('initial_landing_page', referrerParams.landing_page)

    amplitude.getInstance().setUserProperties(referrerParams)
    amplitude.getInstance().identify(identifyEvent2) // send initial referrer

    sessionStorage.setItem(SESSION_REFERRER_SEND, 'true')
  }, [amplitudeInited])
}

export const getCurrentUtmsFromSourcebuster = () => {
  const utms: Record<string, string> = {}

  Object.entries(UTMS_FROM_SOURCEBUSTER).forEach(([key, value]) => {
    const paramValue = sourcebuster.get.current[key]
    if (paramValue && paramValue !== '(none)') {
      utms[`${value}`] = paramValue
    }
  })

  return utms
}

export const getSessionUtms = () => {
  const utms = getUtmsFromSessionStorage()
  const utmsFormSourceBuster = getCurrentUtmsFromSourcebuster()
  Object.entries(utmsFormSourceBuster).forEach(([key, value]) => {
    if (!(key in utms)) {
      utms[key] = value
    }
  })

  return utms
}

export const useSaveUtmsToBackend = (trigger: boolean) => {
  const { globalUser } = useAuthContext()
  const { isWebViewMode } = useWebViewMode()
  const isSavingInProgress = useRef(false)

  useEffect(() => {
    const alreadySend =
      sessionStorage.getItem(SESSION_CHECKOUT_UTMS_SEND) === 'true'
    const isSkipSave =
      isWebViewMode || !trigger || alreadySend || isSavingInProgress.current

    if (isSkipSave) return undefined

    const saveUtms = async () => {
      const token = await globalUser?.getIdToken()
      if (!token) return

      const utms = getSessionUtms()
      if (!Object.keys(utms).length) return

      isSavingInProgress.current = true

      await saveUserUtm(token, utms)
      sessionStorage.setItem(SESSION_CHECKOUT_UTMS_SEND, 'true')
    }

    saveUtms()

    return () => {
      isSavingInProgress.current = false
    }
  }, [trigger, globalUser, isWebViewMode])
}

export default useSaveUTMsToAmplitude
