import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from 'firebase/remote-config'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { DEFAULT_REMOTE_CONFIG } from '@constants'

import firebaseInit from '@utils/firebase'

export type ConfigContext = Record<string, any>

interface Props {
  children: React.ReactNode
}

export const RemoteConfigContext = createContext<Partial<ConfigContext>>(
  DEFAULT_REMOTE_CONFIG
)

export const useRemoteConfig = () => useContext(RemoteConfigContext)

const RemoteConfigProvider: React.FC<Props> = ({ children }: Props) => {
  const [config, setConfig] = useState<ConfigContext>(DEFAULT_REMOTE_CONFIG)

  // get remote config
  useEffect(() => {
    const firebaseApp = firebaseInit()
    if (!firebaseApp) return

    const remoteConfig = getRemoteConfig()
    remoteConfig.settings.minimumFetchIntervalMillis = 320
    remoteConfig.defaultConfig = DEFAULT_REMOTE_CONFIG

    fetchAndActivate(remoteConfig)
      .then(() => getAll(remoteConfig))
      .then((configData) => {
        const receivedConfig: Record<string, any> = {}
        Object.entries(configData).forEach(([key, value]) => {
          receivedConfig[key] = value.asString()
        })

        // Filter the keys, now we have only web keys in context
        const defaultKeys = Object.keys(DEFAULT_REMOTE_CONFIG)
        const configKeys = Object.keys(configData)
        const filteredConfig: Record<string, any> = {}
        const webKeys = configKeys.filter((key) => defaultKeys.includes(key))

        webKeys.forEach((configKey: string) => {
          filteredConfig[configKey] = receivedConfig[configKey]
        })

        setConfig((prevConfig: Partial<ConfigContext>) => ({
          ...prevConfig,
          ...filteredConfig,
          isFetched: true,
        }))
      })
      .catch(() => {
        setConfig({ ...DEFAULT_REMOTE_CONFIG, isFetched: true })
      })
  }, [])

  const handleSetConfig = useCallback(
    (newConfig: Record<string, any>): void => {
      setConfig((prevState) => ({ ...prevState, ...newConfig }))
    },
    []
  )

  const contextValue = useMemo(
    () => ({ remoteConfig: config, handleSetConfig }),
    [config, handleSetConfig]
  )

  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

interface ExtendedProps {
  remoteConfig: ConfigContext
}

export const withConfigContext =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<Omit<P, keyof ExtendedProps>> =>
  // eslint-disable-next-line react/display-name
  (props) =>
    (
      <RemoteConfigContext.Consumer>
        {(config) => (
          <WrappedComponent {...(props as P)} remoteConfig={config} />
        )}
      </RemoteConfigContext.Consumer>
    )

export default RemoteConfigProvider
