import Script from 'next/script'
import { FC } from 'react'

import { useAppSelector } from '@reduxStore/hooks'

import { IS_PRODUCTION } from '@constants_folder/common'

import useInitializeCommonPixels from '@hooks/analytics/useInitializeCommonPixels'
import useInitializeFacebook from '@hooks/analytics/useInitializeFacebook'
import useAddGrowthBookAttributes from '@hooks/useAddGrowthBookAttributes'
import { usePageView } from '@hooks/usePageView'
import { useSaveUserConsent } from '@hooks/useSaveUserConsent'

import useSaveUTMsToAmplitude from '@utils/useSaveUTMsToAmplitude'

import ClarityAnalyticsScript from '@modules/Clarity/ClarityAnalyticsScript'
import ConsentModeScript from '@modules/GTMConsentMode/ConsentModeScript'

const CommonScripts: FC = () => {
  const isInitializeFacebook = useAppSelector(
    (state) => state?.common.isInitializeFacebook
  )

  useInitializeCommonPixels()
  useInitializeFacebook()
  useSaveUTMsToAmplitude()
  usePageView()
  useSaveUserConsent()
  useAddGrowthBookAttributes()

  return (
    <>
      <Script
        id="snap_pixel"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                 (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                 {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                 a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                 r.src=n;var u=t.getElementsByTagName(s)[0];
                 u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');
                `,
        }}
      />
      <Script
        id="twitter_pixel_script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          `,
        }}
      />
      <Script
        id="tiktok_pixel_script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
            ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
            ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
            for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
            ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
            ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
            var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
            var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
            ttq.page();
          }(window, document, 'ttq');
          `,
        }}
      />
      {/* Pinterest Tag */}
      <Script
        id="pinterest_pixel_script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e){if(!window.pintrk){window.pintrk = function () {
          window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
          n=window.pintrk;n.queue=[],n.version="3.0";var
          t=document.createElement("script");t.async=!0,t.src=e;var
          r=document.getElementsByTagName("script")[0];
          r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
          pintrk('load', '2612713431038');
          pintrk('page');
          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src="https://ct.pinterest.com/v3/?event=init&tid=2612713431038&noscript=1"
        />
      </noscript>
      {/* End Pinterest Tag */}

      {/* The script must be loaded in this order for it to work properly: */}
      {/* Custom Consent Mode script */}
      {/* gtag / GTM script */}
      {/* CookieYes script */}

      {/* Custom Consent Mode */}
      <ConsentModeScript />
      {/* End Custom Consent Mode */}

      {/* Google Tag Manager */}
      <Script
        id="google_tag_manager_script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WKZFDV3');
          `,
        }}
      />
      {/* End Google Tag Manager */}
      {/* Facebook Pixel Code */}
      {isInitializeFacebook && (
        <Script
          id="facebook_pixel_script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID});
            fbq('track', 'PageView');
          `,
          }}
        />
      )}

      <ClarityAnalyticsScript />
      {/* Start cookieyes banner */}
      {IS_PRODUCTION && (
        <Script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/5c36f162abd32a8a5076542c/script.js"
        />
      )}
    </>
  )
}

export default CommonScripts
