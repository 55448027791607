const LIVE_MODE = process.env.NEXT_PUBLIC_LIVE_MODE === 'true'

export const USE_CANCEL_BTN = 'use_cancel_btn'
export const COMPLIANCE_CONFIG = 'compliance_config_promova'
export const USE_FULL_SUBS_INFO = 'use_full_subs_info'
export const USE_MARKETING_CONSENT = 'use_marketing_consent'
export const PREMIUM_LESSONS_CONFIG = 'premium_lessons_config'
export const USE_FREE_UKRAINE = 'use_free_ukraine'
export const USE_APPLE_PAY = 'use_apple_pay_platform'
export const USE_GOOGLE_PAY = 'use_google_pay_platform'
export const PAYNETICS_COMPLIANCE_CHECKBOX = 'paynetics_compliance_checkbox'
export const USE_SAND_BOX = 'use_sand_box'
export const USE_NEW_CHECKOUT_FORM_V2 = 'use_new_checkout_form_v2'
export const SHOW_FREE_FOR_UKRAINE_BUTTON = 'show_free_for_ukraine_button'
export const USE_7DAY_CHALLENGE = 'use_7day_challenge'
export const USE_ALT_CANCELLATION_FLOW = 'use_alt_cancellation_flow'
export const USE_NEW_REDESIGNED_MAIN_PAGE = 'use_new_redesigned_main_page'
export const USE_INTRO_LESSON_WEB = 'use_intro_lesson_web'
export const USE_PRACTICE_WITH_AI_STEP = 'use_practice_with_ai_step'
export const USE_HEADER_BUTTON_FOR_BUSINESS = 'use_header_button_for_business'

export const DEFAULT_REMOTE_CONFIG = {
  [USE_CANCEL_BTN]: false,
  [COMPLIANCE_CONFIG]: JSON.stringify({
    [USE_CANCEL_BTN]: true,
    [USE_FULL_SUBS_INFO]: true,
    [USE_MARKETING_CONSENT]: true,
  }),
  [PREMIUM_LESSONS_CONFIG]: '',
  [USE_FREE_UKRAINE]: false,
  [PAYNETICS_COMPLIANCE_CHECKBOX]: false,
  isDefaultFetched: false,
  isFetched: false,
  [USE_APPLE_PAY]: false,
  [USE_GOOGLE_PAY]: false,
  [USE_SAND_BOX]: !LIVE_MODE,
  [USE_NEW_CHECKOUT_FORM_V2]: 'default_checkout_form',
  [SHOW_FREE_FOR_UKRAINE_BUTTON]: false,
  [USE_7DAY_CHALLENGE]: false,
  [USE_ALT_CANCELLATION_FLOW]: false,
  [USE_INTRO_LESSON_WEB]: 'default',
  [USE_PRACTICE_WITH_AI_STEP]: false,
  [USE_HEADER_BUTTON_FOR_BUSINESS]: false,
}
