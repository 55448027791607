import { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import { setDyslexicMode } from '@reduxStore/reducers/theme/themeSlice'

import { ACCESSIBILITY } from '@constants'

import useAddBodyClassName from '@hooks/useAddBodyClassName'

export const useTheme = () => {
  const dispatch = useAppDispatch()

  const dyslexicMode = useAppSelector((state) => state.theme.dyslexicMode)
  const userProfile = useAppSelector((state) => state.profile.userProfile)

  useAddBodyClassName('body_font')

  const userThemeIsSet = useRef(false)

  useEffect(() => {
    if (userProfile?.user_id && !userThemeIsSet.current) {
      const accessibilitySettings = JSON.parse(
        userProfile?.keeper?.[ACCESSIBILITY]?.value || '{}'
      )

      const dislexicMode = accessibilitySettings?.dyslexic || false

      dispatch(setDyslexicMode(dislexicMode))

      userThemeIsSet.current = true
    }
  }, [userProfile])

  useEffect(() => {
    document.body.setAttribute('data-dyslexic', `${dyslexicMode}`)
  }, [dyslexicMode])
}
