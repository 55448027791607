export const SL_APP_PROMO_SHOWING_COUNT = 'sl_app_promo_showing_count'
export const SL_LAST_OPENED_LESSON = 'sl_last_opened_lesson'
export const SL_LAST_COMPLETED_LESSON = 'sl_last_completed_lesson'

export const SESSION_UTMS_SEND = 'session_utms_send'
export const SESSION_REFERRER_SEND = 'session_referrer_send'
export const SESSION_FEED_VIEW_EVENT_SEND = 'session_feed_view_event_send'
export const SESSION_SCROLL_TOPICS_EVENT_SEND =
  'session_scroll_topics_event_send'
export const SESSION_DP_VIEW_EVENT_SEND = 'session_dp_view_event_send'
export const SESSION_DP_VIEW_RETENO_EVENT_SEND =
  'session_dp_view_reteno_event_send'
export const SESSION_DP_AB_TEST_EVENT_SEND = 'session_dp_ab_test_event_send'
export const WEB_VIEW = 'web_view'
export const HIDE_HEADER = 'hide_header'

export const CTA_POPUP_HOW_TO_PRONOUNCE_ALREADY_SHOWN =
  'cta_popup_how_to_pronounce_already_shown'
export const CTA_BANNER_MOBILE_ONLY_ALREADY_SHOWN =
  'cta_banner_mobile_only_already_shown'
export const CTA_POPUP_ALL_PAGES_ALREADY_SHOWN =
  'cta_popup_all_pages_already_shown'

export const CTA_TUTORING_POPUP_ALREADY_SHOWN =
  'cta_tutoring_popup_already_shown'

export const DAILY_PLAN_COMPLETED_POPUP_ALREADY_SHOWN =
  'daily_plan_completed_popup_already_shown'
export const DAILY_PLAN_COMPLETED_COURSE_POPUP_ALREADY_SHOWN =
  'daily_plan_completed_course_popup_already_shown'
export const DAILY_PLAN_FIRST_LESSON_OPENED_DATE =
  'daily_plan_first_lesson_opened_date'

export const FIRST_SESSION_PAGE_VIEW_SEND = 'first_session_page_view_send'
export const SESSION_7DAY_CHALLENGE_SPLIT_SEND =
  'session_7day_challenge-split_send'
export const SESSION_PRICING_PAGE_V2_SPLIT_SEND =
  'session_pricing_page_v2_split_send'
export const SESSION_ALT_CANCELLATION_FLOW_SPLIT_SEND =
  'session_alt_cancellation_flow_split_send'
export const SESSION_CHECKOUT_UTMS_SEND = 'session_checkout_utms_send'
export const SESSION_USER_EMAIL_PROPERTY_SEND =
  'session_user_email_property_send'
export const SESSION_UX_IMPROVEMENTS_SPLIT_SEND =
  'session_ux_improvements_split_send'
export const SESSION_NEW_APP_PROMO_FLOW_SPLIT_SEND =
  'session_new_app_promo_flow_split_send'

export const CTA_POPUP_DONT_GO_ALREADY_SHOWN = 'cta_popup_dont_go_already_shown'
export const IS_USER_LOGGED_OUT = 'is_user_logged_out'
export const SESSION_INTRO_LESSON_SPLIT_SEND = 'session_intro_lesson_split_send'
export const SL_INTRO_LESSON_COMPLETED = 'sl_intro_lesson_completed'

export enum CookieKeys {
  STS_TOKEN_MANAGER = 'stsTokenManager',
}

export const TIME_LEFT = 'time_left'
export const ALREADY_SHOWN = 'already_shown'

export const SESSION_PRACTICE_WITH_AI_STEP_SPLIT_SEND =
  'session_practice_with_ai_step_split_send'
export const SESSION_USE_HEADER_BUTTON_FOR_BUSINESS =
  'session_use_header_button_for_business'
