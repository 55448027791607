import { Themes } from '@_types/index'

export interface ThemeState {
  dyslexicMode: boolean
  specialUITheme: Themes
}

export enum ThemeActions {
  SET_DYSLEXIC_MODE = 'setDyslexicMode',
  SET_SPECIAL_UI_THEME = 'setSpecialUITheme',
}
