import { createSlice } from '@reduxjs/toolkit'

import { Themes } from '@_types/index'

import reducers from './themeReducer'
import { ThemeState } from './themeTypes'

const initialState: ThemeState = {
  dyslexicMode: false,
  specialUITheme: Themes.DEFAULT,
}

export const themeSlice = createSlice({ name: 'theme', initialState, reducers })

export const { setDyslexicMode, setSpecialUITheme } = themeSlice.actions

export default themeSlice.reducer
