import axios, { AxiosResponse } from 'axios'

import axiosInstance from '@api/axiosInstanse'

import { KeeperData } from '@_types/index'

import { sendAxiosErrorAnalytics } from '@utils/analytics'
import { sleep } from '@utils/helpers'

const API_HOST = process.env.NEXT_PUBLIC_API_HOST

export const getProfile = async (
  token: string
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/profiles`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return {
    profile: res?.data,
    status: res?.status,
  }
}

export const createProfile = async (
  token: string,
  payload: { location: string }
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.post(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

export const editProfile = async (
  token: string,
  payload: Record<string, any>
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.patch(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

let retryTimes = 0

export const editProfileV1 = async (
  token: string,
  payload: Record<string, any>
): Promise<Record<string, any> | null> => {
  const _axiosInstance = axios.create()
  _axiosInstance.interceptors.response.use(undefined, async (err) => {
    if (err?.response?.status === 400) {
      await sleep(1000)

      retryTimes += 1

      if (retryTimes < 4) {
        _axiosInstance(err?.config)
      } else {
        throw err
      }
    }

    sendAxiosErrorAnalytics(err)
    throw err
  })

  const res = await axiosInstance.patch(`${API_HOST}/v1/profiles`, payload, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const addDataToProfile = async (
  token: string,
  data: KeeperData
): Promise<Record<string, any> | null> => {
  const res = await axiosInstance.put(
    `${API_HOST}/v1/profiles/keeper/${data.key}`,
    { value: data.payload },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res
}

export const getDataToProfile = async (
  token: string,
  key: string
): Promise<{ value: string } | null> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/profiles/keeper/${key}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res?.data
}

export const getAvailableAvatars = async (
  token: string
): Promise<AxiosResponse> => {
  const res = await axiosInstance.get(`${API_HOST}/v1/profiles/avatars`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}
