import { useGrowthBook } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useQueryCountry } from '@utils/useQueryCountry'

import { useAuthContext } from '@modules/Auth/AuthProvider'

const useAddGrowthBookAttributes = () => {
  const growthBook = useGrowthBook()
  const { country } = useQueryCountry()
  const { globalUser } = useAuthContext()
  const { query, isReady } = useRouter()

  const userId = globalUser?.uid

  useEffect(() => {
    if (!userId || !growthBook || !country || !isReady) return

    const isMobile = navigator.maxTouchPoints > 0 && 'orientation' in window
    const utmSource = query?.utm_source || 'organic'

    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      custom_user_id: userId,
      country,
      utm_source: utmSource,
      device: isMobile ? 'mobile' : 'desktop',
    })
  }, [userId, growthBook, country, query, isReady])
}

export default useAddGrowthBookAttributes
