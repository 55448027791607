import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import '@styles/globals.css'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { de, en, es, fr, it, pl, pt, tr, uk } from 'make-plural/plurals'
import { NextPage } from 'next'
import type { AppContext, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { store } from '@reduxStore/store'

import {
  DISCOUNT_TIMER_NAME,
  EXTRA_DISCOUNT_TIMER_NAME,
  GROWTH_BOOK_DEFAULT_VALUE,
  HOTJAR_SITE_ID,
  HOTJAR_SV,
} from '@constants'

import { useSaveEntryPoint, useSaveUtmsToLS } from '@hooks/saveEntryPoint'
import useBlockAnalytics from '@hooks/useBlockAnalytics'
import { useRedirectToDefaultLocale } from '@hooks/useRedirectToDefaultLocale'
import useWebViewMode from '@hooks/useWebViewMode'

import { sendAnalytics } from '@utils/analytics'
import useLocale from '@utils/customHooks/useLocales'
import { ImpactIdentify } from '@utils/impact'
import loadCatalog from '@utils/loadCatalog'

import NoConnectionAlert from '@features/NoConnectionAlert/NoConnectionAlert'

import AuthProvider from '@modules/Auth/AuthProvider'
import RemoteConfigProvider from '@modules/RemoteConfig/RemoteConfigProvider'

import CommonHead from '@elements/CommonHead'
import CommonScripts from '@elements/CommonScripts'
import ErrorBoundary from '@elements/ErrorBoundary/ErrorBoundary'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('uk', { plurals: uk })
i18n.loadLocaleData('es', { plurals: es })
i18n.loadLocaleData('pt', { plurals: pt })
i18n.loadLocaleData('fr', { plurals: fr })
i18n.loadLocaleData('tr', { plurals: tr })
i18n.loadLocaleData('it', { plurals: it })
i18n.loadLocaleData('de', { plurals: de })
i18n.loadLocaleData('pl', { plurals: pl })

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter()
  const { query } = router

  const firstRender = useRef(true)
  const locale = router?.locale || router?.defaultLocale

  if (pageProps?.translation && firstRender.current) {
    loadCatalog(locale)
    firstRender.current = false
  }

  useBlockAnalytics()

  // init hotjar
  useEffect(() => {
    hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_SV)
  }, [])

  // clear timer
  useEffect(() => {
    const clearTimer = query?.clear_timer

    if (clearTimer) {
      localStorage.removeItem(DISCOUNT_TIMER_NAME)
      localStorage.removeItem(EXTRA_DISCOUNT_TIMER_NAME)
    }
  }, [query?.clear_timer])

  // add store to window to access it outside components
  // and avoid creating dependency cycle
  useEffect(() => {
    ;(window as any)._store = store
  }, [])

  // load dictionaries
  useLocale()

  // save entry point
  useSaveEntryPoint()

  // save utms to LC
  useSaveUtmsToLS()

  // save webview mode from query to sessionStorage
  useWebViewMode()

  const growthBook = new GrowthBook({
    apiHost: process.env.NEXT_PUBLIC_GROWTH_BOOK_API_KEY,
    clientKey: process.env.NEXT_PUBLIC_GROWTH_CLIENT_KEY,
    enableDevMode: process.env.NEXT_PUBLIC_LIVE_MODE !== 'true',
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      if (result.value !== GROWTH_BOOK_DEFAULT_VALUE) {
        sendAnalytics({
          eventName: 'gen_ab_test',
          data: {
            param_name: experiment.key,
            param_values: result.value,
          },
        })
      }
    },
  })

  growthBook.init({
    streaming: false,
  })

  useRedirectToDefaultLocale()

  const [queryClient] = useState(() => new QueryClient())

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <RemoteConfigProvider>
          <GrowthBookProvider growthbook={growthBook}>
            <I18nProvider i18n={i18n}>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <Hydrate state={pageProps?.dehydratedState}>
                    <CommonHead
                      availableLocales={pageProps?.availableLocales}
                    />
                    <CommonScripts />
                    <NoConnectionAlert />
                    <ImpactIdentify />
                    {getLayout(<Component {...pageProps} />)}
                    <ReactQueryDevtools />
                  </Hydrate>
                </QueryClientProvider>
              </AuthProvider>
            </I18nProvider>
          </GrowthBookProvider>
        </RemoteConfigProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default MyApp

MyApp.getInitialProps = async (appContext: AppContext) => {
  const res = appContext?.ctx?.res

  if (process.env.NEXT_PUBLIC_LIVE_MODE === 'true')
    res?.setHeader(
      'Cache-Control',
      'public, max-age=0, s-maxage=3600, stale-while-revalidate=86400'
    )

  return {}
}
