import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'

import { ThemeActions, ThemeState } from './themeTypes'

const reducers: SliceCaseReducers<ThemeState> = {
  [ThemeActions.SET_DYSLEXIC_MODE]: (
    state: ThemeState,
    action: PayloadAction<ThemeState['dyslexicMode']>
  ) => ({
    ...state,
    dyslexicMode: action.payload,
  }),
  [ThemeActions.SET_SPECIAL_UI_THEME]: (
    state: ThemeState,
    action: PayloadAction<ThemeState['specialUITheme']>
  ) => ({
    ...state,
    specialUITheme: action.payload,
  }),
}
export default reducers
