import amplitude from 'amplitude-js'
import { sha256 } from 'js-sha256'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@reduxStore/hooks'
import { setAmplitudeInited } from '@reduxStore/reducers/amplitude/amplitudeSlice'

import { IS_PRODUCTION } from '@constants'

import useSourceBuster from '@hooks/useSourceBuster'

import { getAnalyticsSettings } from '@utils/analytics'

const snapId = 'adbd86b3-fcbb-451a-b2f6-9086f513c073'

const useInitializeCommonPixels = () => {
  const { isReady } = useRouter()
  const dispatch = useAppDispatch()

  const userEmail = useAppSelector((state) => state.profile.userProfile?.email)

  useSourceBuster()

  useEffect(() => {
    const initPixelSnapchat = () => {
      const { snaptr: snapPixel } = window as any
      const { blockSnapchatAnalytics } = getAnalyticsSettings()
      const isSnapchatCanInit =
        snapPixel && (!blockSnapchatAnalytics || IS_PRODUCTION)

      if (isSnapchatCanInit) {
        try {
          if (userEmail) {
            snapPixel('init', snapId, { user_email: sha256(userEmail) })
          } else {
            snapPixel('init', snapId)
          }
        } catch {
          // error
        }
      }
    }

    initPixelSnapchat()
  }, [userEmail])

  useEffect(() => {
    const initPixelTwitter = () => {
      const { twq: twitter } = window as any
      const { blockTwitterAnalytics } = getAnalyticsSettings()
      const isTwitterCanInit =
        twitter && (!blockTwitterAnalytics || IS_PRODUCTION)

      if (isTwitterCanInit) {
        try {
          twitter('config', 'ohhff')
        } catch {
          // error
        }
      }
    }

    initPixelTwitter()
  }, [])

  useEffect(() => {
    const initAmplitudePixel = () => {
      if (!isReady) return
      amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '', undefined, {
          includeUtm: true,
          includeReferrer: true,
          saveParamsReferrerOncePerSession: false,
          includeFbclid: true,
        })
      // set user properties before send any analytics
      amplitude.getInstance().setUserProperties({
        screen_size: `${window?.innerWidth}x${window?.innerHeight}`,
        user_agent: navigator?.userAgent,
      })

      dispatch(setAmplitudeInited(true))
    }

    initAmplitudePixel()
  }, [isReady, dispatch])
}

export default useInitializeCommonPixels
